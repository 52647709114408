<template>
  <div>
    <h1
      class="border-0 font-semibold border-b border-solid border-gray-400 py-3 px-2 text-blackdok text-xl"
    >
      Changer Mon mot de passe
    </h1>
    <ValidationObserver ref="observer">
      <div class="px-12 mt-5">
        <div class="flex flex-col xl:flex-row xl:items-center mb-5">
          <label for="currect_password" class="xl:mr-5 xl:w-1/3 mb-2 xl:mb-0"
            >Mot de passe actuel</label
          >
          <ValidationProvider
            :bails="false"
            name="Mot de passe actuel"
            rules="required"
            id="currect_password"
            class="w-full"
          >
            <div slot-scope="{ errors }">
              <input
                autocomplete="off"
                type="password"
                placeholder="Mot de passe actuel"
                v-model="actualPassword"
                class="bg-white border border-solid border-gray-300 px-2 py-3 rounded-cu w-full xl:mr-2"
                :class="errors[0] ? 'border border-red-600 border-solid' : ''"
              />
              <p v-if="errors[0]" class="text-red-700">
                Veuillez remplir ce champ
              </p>
            </div>
          </ValidationProvider>
        </div>
        <div class="flex flex-col xl:flex-row xl:items-center mb-5">
          <label class="xl:mr-5 xl:w-1/3 mb-2 xl:mb-0"
            >Nouveau mot de passe</label
          >
          <ValidationProvider
            :bails="false"
            name="Nouveau mot de passe"
            rules="required|verify_password|min:8"
            class="w-full"
          >
            <div slot-scope="{ errors }">
              <input
                autocomplete="off"
                type="password"
                placeholder="Nouveau mot de passe"
                v-model="newPassword"
                class="bg-white border border-solid border-gray-300 px-2 py-3 rounded-cu w-full xl:mr-2"
                :class="errors[0] ? 'border border-red-600 border-solid' : ''"
                @input="onWatch"
              />
              <div
                v-if="newPassword && newPassword.length"
                class="h-1 rounded-full my-2"
                :style="
                  `width: ${percentPassword()}%; background: ${percentBackground(
                    percentPassword()
                  )}`
                "
              ></div>
              <div
                class="flex flex-col"
                v-if="newPassword && newPassword.length"
              >
                <span
                  :class="
                    validation.has_number ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_number"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_number"
                  ></i
                  >Veuillez saisir quelques chiffres
                </span>
                <span
                  :class="
                    validation.has_lowercase ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_lowercase"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_lowercase"
                  ></i
                  >Veuillez saisir des lettres minuscules
                </span>
                <span
                  :class="
                    validation.has_uppercase ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_uppercase"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_uppercase"
                  ></i
                  >Veuillez saisir des lettres majuscules
                </span>
                <span
                  :class="
                    validation.has_special ? 'text-green-500' : 'text-red-500'
                  "
                >
                  <i
                    class="far fa-check-circle"
                    v-if="validation.has_special"
                  ></i
                  ><i
                    class="far fa-times-circle"
                    v-if="!validation.has_special"
                  ></i
                  >Veuillez saisir quelques caractères spéciaux
                </span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="flex flex-col xl:flex-row xl:items-center mb-5">
          <label for="confirm_password" class="xl:mr-5 xl:w-1/3 mb-2 xl:mb-0"
            >Confirmer le mot de passe</label
          >
          <ValidationProvider
            :bails="false"
            name="Confirmer le mot de passe"
            rules="required|confirmed:password"
            id="confirm_password"
            class="w-full"
          >
            <div slot-scope="{ errors }">
              <input
                autocomplete="off"
                type="password"
                placeholder="Confirmer le mot de passe"
                v-model="confirmNewPassword"
                class="bg-white border border-solid border-gray-300 px-2 py-3 rounded-cu w-full xl:mr-2"
                :class="errors[0] ? 'border border-red-600 border-solid' : ''"
              />
              <p v-if="errors[0]" class="text-red-700">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
    <div class="w-full flex flex-row justify-end mt-5 mb-0">
      <button
        @click="savePassword"
        class="border-2 border-solid border-dokBlue-ultra text-dokBlue-ultra hover:text-white bg-transparent hover:bg-dokBlue-ultra py-3 px-3 rounded-cu transition delay-150 duration-300 ease-in-out cursor-pointer font-EffraR w-full xl:w-auto"
      >
        Sauvegarder
      </button>
    </div>
  </div>
</template>
<script>
import VuePassword from "vue-password";
export default {
  components: { VuePassword },
  data() {
    return {
      actualPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false
      }
    };
  },
  watch: {
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true
    }
  },
  methods: {
    onWatch() {
      this.validation.has_number = /\d/.test(this.newPassword);
      this.validation.has_lowercase = /[a-z]/.test(this.newPassword);
      this.validation.has_uppercase = /[A-Z]/.test(this.newPassword);
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.newPassword
      );
    },
    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 25;
      if (this.validation.has_lowercase) precent = precent + 25;
      if (this.validation.has_uppercase) precent = precent + 25;
      if (this.validation.has_special) precent = precent + 25;

      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 25) return "red";
      if (val === 50) return "yellow";
      if (val === 75) return "blue";
      if (val === 100) return "green";
    },
    async savePassword() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      this.$store.dispatch("auth/UPDATE_PASSWORD", {
        data: {
          actualPass: this.actualPassword,
          newPass: this.newPassword,
          confPass: this.confirmNewPassword
        },
        onData: this.onData
      });
    },
    onData(data) {
      this.$vs.notify({
        time: 4000,
        text: data.message,
        color: data.ok ? "success" : "danger",
        iconPack: "feather",
        icon: data.ok ? "icon-check" : "icon-x"
      });
      if (data.ok) {
        this.actualPassword = null;
        this.newPassword = null;
        this.confirmNewPassword = null;
        this.$refs.observer.reset();
      }
    }
  }
};
</script>
